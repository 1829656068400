<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'users-roles-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Designation</th>
          <th scope="col">Description</th>

          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user_role, index) in user_roles" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ user_role.reference }}
          </td>
          <td>
            {{ user_role.fullName }}
          </td>
          <td>
            {{ user_role.description }}
          </td>

          <td class="d-flex">
            <button
              @click="
                $router.push({
                  name: 'users-roles-edit',
                  params: { reference: user_role.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-pencil-square fs-5 text-warning"></i>
            </button>

            <button @click="remove(user_role)" class="btn">
              <i class="bi bi-archive fs-5 text-danger"></i>
            </button>

            <button class="btn">
              <i class="bi bi-cast fs-5 text-info"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("user", {
      user_roles: "getUserRoles",
    }),
  },
  mounted() {
    this.$store.dispatch("user/getUserRoles");
  },
  methods: {
    async refresh() {
      return await this.$store.dispatch("user/getUserRoles");
    },

    async remove(data) {
      let user_role = data;
      await this.$confirm({
        message: "Vous voulez supprimer le role  " + user_role.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("user/destroyRole", user_role.reference);
          }
        },
      });
    },
  },
};
</script>
